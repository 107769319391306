import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OwnedBusinesses } from '@lama/app-components';
import { allApplicationPeopleSelector } from '@lama/data-formatters';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useCreateBusinessMutation } from '../../hooks/react-query/useCreateBusinessMutation';
import { useUpdatePerson } from '../../hooks/react-query/useUpdatePerson';
import { useAddApplicationRelation } from '../../hooks/react-query/useAddApplicationRelation';
import { useRemoveApplicationRelation } from '../../hooks/react-query/useRemoveApplicationRelation';

interface OwnedBusinessesScreenProps extends ScreenProps {
  title?: string;
}

export const OwnedBusinessesScreen: React.FC<OwnedBusinessesScreenProps> = ({
  flow,
  onNextClick,
  onBackClick,
  saveLoading,
  title,
  ...stepsNavigationProps
}) => {
  const { application } = useContext(ApplicationContext);
  const requirement = useGetCurrentRequirement();
  const { t } = useTranslation();
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(application.id);
  const { mutateAsync: addApplicationRelation, isPending: addingApplicationRelation } = useAddApplicationRelation();
  const { mutateAsync: removeApplicationRelation, isPending: removingApplicationRelation } = useRemoveApplicationRelation();

  const owner = useMemo(() => {
    if (!requirement) {
      return null;
    }

    const { entityId, entityType } = requirement;

    if (entityType === 'person') {
      const person = allApplicationPeopleSelector(application).find(({ id }) => id === entityId);

      return person ? { entityType: 'person' as const, entity: person } : null;
    } else if (entityType === 'business') {
      const business = application.relatedBusinesses.find(({ business: { id } }) => id === entityId)?.business;

      return business ? { entityType: 'business' as const, entity: business } : null;
    }

    return null;
  }, [application, requirement]);

  if (!owner || !application) {
    return null;
  }

  return (
    <BasicScreen
      {...stepsNavigationProps}
      flow={flow}
      title={title?.length ? title : requirement?.name ?? t('ownedBusinesses.title')}
      subtitle={requirement?.description ?? t('ownedBusinesses.title')}
      onSaveClick={onNextClick}
      onBackClick={onBackClick}
      saveLoading={updatingBusiness || saveLoading}
      saveVisible={flow === 'onboarding'}
    >
      <OwnedBusinesses
        application={application}
        owner={owner}
        createBusiness={createBusiness}
        updateBusiness={updateBusiness}
        updatePerson={updatePerson}
        addApplicationRelation={addApplicationRelation}
        removeApplicationRelation={removeApplicationRelation}
        loading={creatingBusiness || updatingBusiness || updatingPerson || addingApplicationRelation || removingApplicationRelation}
      />
    </BasicScreen>
  );
};
