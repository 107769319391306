import React, { useCallback } from 'react';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Flex } from '@lama/design-system';
import { ButtonWithSaveIndication } from '@lama/app-components';
import type { Flow } from '../ScreenProps';
import { DismissRequirementButton } from './genericRequirements/documentRequirement/DismissRequirementButton';
import type { StepsNavigationProps } from './StepsNavigation';

interface NextButtonProps extends LoadingButtonProps {
  children: React.ReactNode;
}

export const NextButton: React.FC<NextButtonProps> = ({ children, ...rest }) => <LoadingButton {...rest}>{children}</LoadingButton>;

export const StepsNavigationOld: React.FC<StepsNavigationProps & { flow: Flow }> = ({
  saveEnabled = true,
  saveVisible = true,
  saveLoading = false,
  backEnabled = true,
  backVisible = true,
  nextButtonText = 'Next',
  nextIconVisible = true,
  onBackClick = () => {
    /* do nothing. */
  },
  onSaveClick = () => {
    /* do nothing. */
  },
  dismissible = false,
  flow,
  successSubmit,
}) => {
  const nextOrBackVisible = saveVisible || backVisible;
  const handleSaveClick = useCallback(() => {
    onSaveClick();
  }, [onSaveClick]);

  const handleBackClick = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  if (!nextOrBackVisible) {
    if (dismissible) {
      return (
        <Flex flexDirection={'column'} alignItems={'center'}>
          <DismissRequirementButton />
        </Flex>
      );
    }

    return null;
  }

  return (
    <Flex flexDirection={'row-reverse'} justifyContent={'space-between'}>
      <Flex flexDirection={'row-reverse'} gap={6}>
        {saveVisible ? (
          flow === 'management' ? (
            <ButtonWithSaveIndication
              disabled={!saveEnabled}
              loading={saveLoading}
              hasBeenSaved={!!successSubmit}
              onSave={handleSaveClick}
            />
          ) : (
            <NextButton
              loading={saveLoading}
              disabled={!saveEnabled}
              endIcon={nextIconVisible ? <ChevronRight /> : null}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              onClick={handleSaveClick}
              sx={{ width: '130px', height: '40px' }}
            >
              {nextButtonText}
            </NextButton>
          )
        ) : null}
        {dismissible ? <DismissRequirementButton /> : null}
      </Flex>
      {backVisible ? (
        <Button
          disabled={!backEnabled}
          size={'large'}
          variant={'contained'}
          color={'neutral'}
          sx={{ minWidth: '40px', width: '40px', height: '40px' }}
          onClick={handleBackClick}
        >
          <ChevronLeft />
        </Button>
      ) : null}
    </Flex>
  );
};
