import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RelatedCompanies } from '@lama/app-components';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useCreateBusinessMutation } from '../../hooks/react-query/useCreateBusinessMutation';

interface OwnersScreenProps extends ScreenProps {
  title?: string;
}

export const RelatedCompaniesScreen: React.FC<OwnersScreenProps> = ({
  flow,
  onNextClick,
  onBackClick,
  saveLoading,
  title,
  ...stepsNavigationProps
}) => {
  const { application } = useContext(ApplicationContext);
  const requirement = useGetCurrentRequirement();
  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement?.entityId)?.business,
    [application.relatedBusinesses, requirement?.entityId],
  );

  const { t } = useTranslation();
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();

  const currentRequirement = useGetCurrentRequirement();

  if (!business || !application) {
    return null;
  }

  return (
    <BasicScreen
      {...stepsNavigationProps}
      flow={flow}
      title={title?.length ? title : currentRequirement?.name ?? t('affiliates.title')}
      subtitle={currentRequirement?.description ?? t('affiliates.subtitle')}
      onSaveClick={onNextClick}
      onBackClick={onBackClick}
      saveLoading={updatingBusiness || saveLoading}
      saveVisible={flow === 'onboarding'}
    >
      <RelatedCompanies
        application={application}
        business={business}
        createBusiness={createBusiness}
        updateBusiness={updateBusiness}
        loading={creatingBusiness || updatingBusiness}
      />
    </BasicScreen>
  );
};
