import type { FC, ReactElement } from 'react';
import React, { useCallback } from 'react';
import { Button, Flex } from '@lama/design-system';
import { useTranslation } from 'react-i18next';
import { EmptySystemLogo } from './EmptySystemLogo';

interface NoConnectedAccountsViewProps {
  emptyLogo: ReactElement;
  connectIcon: ReactElement;
  connectText: string;
  connectAccount: () => void;
  setFillingOption: (option: 'auto' | 'manual') => void;
  allowUpload?: boolean;
  initializingPlaidConnection?: boolean;
}

export const NoConnectedAccountsView: FC<NoConnectedAccountsViewProps> = ({
  emptyLogo,
  connectIcon,
  connectText,
  connectAccount,
  setFillingOption,
  allowUpload = true,
  initializingPlaidConnection = false,
}) => {
  const { t } = useTranslation();

  const onUploadFiles = useCallback(() => {
    setFillingOption('manual');
  }, [setFillingOption]);

  return (
    <Flex flexDirection={'column'} gap={8} alignItems={'center'}>
      <EmptySystemLogo logo={emptyLogo} />
      <Flex flexDirection={'column'} gap={4} width={'80%'}>
        <Button startIcon={connectIcon} onClick={connectAccount} loading={initializingPlaidConnection}>
          {connectText}
        </Button>
        {allowUpload ? (
          <Button variant={'secondary'} onClick={onUploadFiles}>
            {t('actions.uploadFiles')}
          </Button>
        ) : null}
      </Flex>
    </Flex>
  );
};
