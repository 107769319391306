import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LoadingPage } from '@lama/app-components';
import { partnerState } from '../../state/appState';
import { useProductsByPartnerQuery } from '../../hooks/react-query/useProductsByPartnerQuery';

export const ProductsScreen: React.FC = () => {
  const partner = useRecoilValue(partnerState);
  const { data: products } = useProductsByPartnerQuery(partner?.name);

  const navigate = useNavigate();

  const defaultProduct = useMemo(
    () => products?.find((p) => p.key === partner?.defaultProductKey) ?? products?.[0],
    [partner?.defaultProductKey, products],
  );

  useEffect(() => {
    if (partner && products?.length && defaultProduct) {
      navigate({ pathname: `/products/${defaultProduct.name}`, search: window.location.search }, { replace: true });
    }
  }, [defaultProduct, navigate, partner, products?.length]);

  return <LoadingPage />;
};
