import React, { useCallback, useMemo } from 'react';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Flex } from '@lama/design-system';
import { ButtonWithSaveIndication, useIsMobile } from '@lama/app-components';
import type { Flow } from '../ScreenProps';
import { useManagementNavigation } from '../managementScreen/hooks/useManagementNavigation';
import { DismissRequirementButton } from './genericRequirements/documentRequirement/DismissRequirementButton';

export interface StepsNavigationProps {
  saveEnabled?: boolean;
  saveVisible?: boolean;
  saveLoading?: boolean;
  nextButtonText?: string;
  backEnabled?: boolean;
  backVisible?: boolean;
  onSaveClick?: (options?: any) => void;
  onBackClick?: (options?: any) => void;
  nextIconVisible?: boolean;
  animate?: boolean;
  dismissible?: boolean;
  successSubmit?: boolean;
}

interface OnboardingNextButtonProps extends LoadingButtonProps {
  children: React.ReactNode;
}

export const OnboardingNextButton: React.FC<OnboardingNextButtonProps> = ({ children, ...rest }) => (
  <LoadingButton {...rest}>{children}</LoadingButton>
);

const NavigationButton: React.FC<{
  direction: 'back' | 'next';
  enabled: boolean;
  onClick: () => void;
}> = ({ direction, enabled, onClick }) => (
  <Button
    disabled={!enabled}
    size={'large'}
    variant={'contained'}
    color={'neutral'}
    sx={{ minWidth: '40px', width: '40px', height: '40px', borderRadius: '4px' }}
    onClick={onClick}
  >
    {direction === 'back' ? <ChevronLeft /> : <ChevronRight />}
  </Button>
);

const ManagementStepNavigation: React.FC<StepsNavigationProps & { flow: Flow }> = ({
  saveEnabled = true,
  saveVisible = true,
  saveLoading = false,
  successSubmit,
  onSaveClick = () => {
    /* do nothing. */
  },
}) => {
  const managementNavigation = useManagementNavigation();
  const isMobile = useIsMobile();

  const SaveAndNextButtons = useMemo(() => {
    if (managementNavigation === null) {
      return null;
    }

    return (
      <>
        {saveVisible ? (
          <ButtonWithSaveIndication
            disabled={!saveEnabled}
            loading={saveLoading}
            hasBeenSaved={!!successSubmit}
            onSave={onSaveClick}
            width={isMobile ? '100%' : undefined}
          />
        ) : null}
        <NavigationButton
          direction={'next'}
          enabled={!saveLoading && managementNavigation.nextEnabled}
          onClick={managementNavigation.goNext}
        />
      </>
    );
  }, [managementNavigation, saveVisible, saveEnabled, saveLoading, successSubmit, onSaveClick, isMobile]);

  if (managementNavigation === null) {
    return null;
  }

  return (
    <Flex justifyContent={'space-between'} width={'100%'} gap={isMobile ? 4 : 2}>
      <NavigationButton
        direction={'back'}
        enabled={!saveLoading && managementNavigation.backEnabled}
        onClick={managementNavigation.goBack}
      />
      {isMobile ? SaveAndNextButtons : <Flex gap={2}>{SaveAndNextButtons} </Flex>}
    </Flex>
  );
};

export const StepsNavigation: React.FC<StepsNavigationProps & { flow: Flow }> = ({
  saveEnabled = true,
  saveVisible = true,
  saveLoading = false,
  backEnabled = true,
  backVisible = true,
  nextButtonText = 'Next',
  nextIconVisible = true,
  onBackClick = () => {
    /* do nothing. */
  },
  onSaveClick = () => {
    /* do nothing. */
  },
  dismissible = false,
  flow,
  successSubmit,
}) => {
  const nextOrBackVisible = saveVisible || backVisible;
  const handleSaveClick = useCallback(() => {
    onSaveClick();
  }, [onSaveClick]);

  const handleBackClick = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  if (!nextOrBackVisible && flow !== 'management') {
    if (dismissible) {
      return (
        <Flex flexDirection={'column'} alignItems={'center'}>
          <DismissRequirementButton />
        </Flex>
      );
    }

    return null;
  }

  return (
    <Flex flexDirection={'row-reverse'} justifyContent={'space-between'}>
      <Flex flexDirection={'row-reverse'} gap={6} width={'100%'}>
        {flow === 'management' ? (
          <ManagementStepNavigation
            saveEnabled={saveEnabled}
            saveVisible={saveVisible}
            saveLoading={saveLoading}
            successSubmit={successSubmit}
            onSaveClick={handleSaveClick}
            flow={flow}
          />
        ) : saveVisible ? (
          <OnboardingNextButton
            loading={saveLoading}
            disabled={!saveEnabled}
            endIcon={nextIconVisible ? <ChevronRight /> : null}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={handleSaveClick}
            sx={{ width: '130px', height: '40px' }}
          >
            {nextButtonText}
          </OnboardingNextButton>
        ) : null}
        {dismissible ? <DismissRequirementButton /> : null}
      </Flex>
      {backVisible ? <NavigationButton direction={'back'} enabled={backEnabled} onClick={handleBackClick} /> : null}
    </Flex>
  );
};
