import type { FC } from 'react';
import React from 'react';
import type { Screen } from '@lama/contracts';
import { GetStartedScreen } from './getStarted/GetStartedScreen';
import { ConclusionScreen } from './conclusion/ConclusionScreen';
import { ServicesSelectionScreen } from './servicesSelection/ServicesSelectionScreen';
import { AssetsScreen } from './assets/AssetsScreen';
import { ServicesConnectionScreen } from './servicesConnection/ServicesConnectionScreen';
import { OverviewScreen } from './overview/OverviewScreen';
import { LoginScreen } from './loginScreen/LoginScreen';
import { IdVerificationScreen } from './idVerification/IdVerificationScreen';
import { BankStatementsScreen } from './bankStatements/BankStatementsScreen';
import { FinancialStatementsScreen } from './financialStatements/FinancialStatementsScreen';
import { ProjectedRevenueScreen } from './projectedRevenue/ProjectedRevenueScreen';
import type { ScreenProps } from './ScreenProps';
import { RealEstateDetailsScreen } from './realEstateDetails/RealEstateDetailsScreen';
import { RequestedFundingTimeScreen } from './RequestedFundingTime/RequestedFundingTimeScreen';
import { AccountServicesSelectionScreen } from './accountServicesSelection/AccountServicesSelectionScreen';
import { PersonalFinancialStatementsScreen } from './personalFinancialStatements/PersonalFinancialStatements';
import { W9FormSignScreen } from './w9FormSign/W9FormSignScreen';
import { TransactionAuthorizationRulesScreen } from './transactionAuthorizationRules/TransactionAuthorizationRules';
import { ProductSelectionScreen } from './productSelection/ProductSelectionScreen';
import { EmbeddedInitialScreen } from './embeddedScreen/embeddedInitialScreen';
import { EmbeddedConclusionScreen } from './embeddedScreen/embeddedConclusionScreen';
import { GenericRequirementForm } from './shared/genericRequirements/GenericRequirementForm';
import { InitialDetailsScreen } from './initialDetails/InitialDetailsScreen';
import { UseOfFundsScreen } from './useOfFunds/UseOfFundsScreen';
import { RequestedAmountScreen } from './requestedAmount/RequestedAmountScreen';
import { OwnersScreen } from './owners/OwnersScreen';
import { RevenueScreen } from './revenue/RevenueScreen';
import { SearchBusinessScreen } from './searchBusiness/SearchBusinessScreen';
import { BusinessRelationScreen } from './businessRelation/BusinessRelationScreen';
import { DocumentRequirementScreen } from './documentRequirement/DocumentRequirementScreen';
import { SignAndSubmitScreen } from './signAndSubmit/SignAndSubmitScreen';
import { ApplicationSubmissionScreen } from './applicationSubmission/ApplicationSubmissionScreen';
import { OwnedBusinessesScreen } from './ownedBusinesses/OwnedBusinessesScreen';
import { VerificationScreen } from './verification/VerificationScreen';
import { DocumentHubScreen } from './documentHub/DocumentHubScreen';
import { FintechMeetupConclusionScreen } from './conclusion/FintechMeetupConclusionScreen';
import { RelationsList } from './relationsList/RelationsList';
import { PropertiesList } from './relationsList/PropertiesList';
import { BorrowingEntityScreen } from './borrowingEntity/BorrowingEntityScreen';
import { RelatedCompaniesScreen } from './affiliates/RelatedCompaniesScreen';
import { GenericListScreen } from './genericList/GenericList';
import { ThemeOverrideProvider } from './shared/ThemeOverrideProvider';
import { SouthStatePersonalFinancialStatementScreen } from './southstatePersonalFinancials/SouthStatePersonalFinancialStatementScreen';

export const screenMap: Record<Screen, FC<ScreenProps>> = {
  embedded: (props: any) => <EmbeddedInitialScreen {...props} />,
  embeddedConclusion: (props: any) => <EmbeddedConclusionScreen {...props} />,
  getStarted: (props: any) => (
    <ThemeOverrideProvider>
      <GetStartedScreen {...props} />
    </ThemeOverrideProvider>
  ),
  requestedAmount: (props: any) => <RequestedAmountScreen {...props} />,
  useOfFunds: (props: any) => <UseOfFundsScreen {...props} />,
  useOfFundsBreakdown: (props: any) => <GenericListScreen {...props} />,
  searchBusiness: (props: any) => <SearchBusinessScreen {...props} />,
  tbdBusinessDetails: (props: any) => <GenericRequirementForm {...props} />,
  businessDetails: (props: any) => <GenericRequirementForm {...props} />,
  businessRelation: (props: any) => <BusinessRelationScreen {...props} />,
  borrowingEntity: (props: any) => <BorrowingEntityScreen {...props} />,
  compliance: (props: any) => <GenericRequirementForm {...props} />,
  conclusion: (props: any) => <ConclusionScreen {...props} />,
  fintechMeetupConclusion: (props: any) => <FintechMeetupConclusionScreen {...props} />,
  servicesSelection: (props: any) => <ServicesSelectionScreen {...props} />,
  servicesConnection: (props: any) => <ServicesConnectionScreen {...props} />,
  assets: (props: any) => <AssetsScreen {...props} />,
  overview: (props: any) => <OverviewScreen {...props} />,
  initialDetails: (props: any) => <InitialDetailsScreen {...props} />,
  login: (props: any) => <LoginScreen {...props} />,
  idVerification: (props: any) => <IdVerificationScreen {...props} />,
  applicationSubmission: (props: any) => <ApplicationSubmissionScreen {...props} />,
  signAndSubmit: (props: any) => <SignAndSubmitScreen {...props} />,
  documentRequirement: (props: any) => <DocumentRequirementScreen {...props} />,
  debts: (props: any) => <GenericListScreen {...props} />,
  collateral: (props: any) => <GenericListScreen {...props} />,
  revenue: (props: any) => <RevenueScreen {...props} />,
  financialStatements: (props: any) => <FinancialStatementsScreen {...props} />,
  bankStatements: (props: any) => <BankStatementsScreen {...props} />,
  projectedRevenue: (props: any) => <ProjectedRevenueScreen {...props} />,
  principals: (props: any) => <OwnersScreen {...props} />,
  owners: (props: any) => <OwnersScreen {...props} />,
  relatedCompanies: (props: any) => <RelatedCompaniesScreen {...props} />,
  ownedBusinesses: (props: any) => <OwnedBusinessesScreen {...props} />,
  personInformation: (props: any) => <GenericRequirementForm {...props} />,
  personVerification: (props: any) => <GenericRequirementForm {...props} />,
  businessVerification: (props: any) => <GenericRequirementForm {...props} />,
  realEstateDetails: (props: any) => <RealEstateDetailsScreen {...props} />,
  requestedFundingTime: (props: any) => <RequestedFundingTimeScreen {...props} />,
  accountServicesSelection: (props: any) => <AccountServicesSelectionScreen {...props} />,
  personalFinancialStatements: (props: any) => <PersonalFinancialStatementsScreen {...props} />,
  w9FormSign: (props: any) => <W9FormSignScreen {...props} />,
  transactionAuthorizationRules: (props: any) => <TransactionAuthorizationRulesScreen {...props} />,
  businessDescription: (props: any) => <GenericRequirementForm {...props} />,
  loanDescription: (props: any) => <GenericRequirementForm {...props} />,
  productSelection: (props: any) => <ProductSelectionScreen {...props} />,
  verification: (props: any) => <VerificationScreen {...props} />,
  genericForm: (props: any) => <GenericRequirementForm {...props} />,
  documentHub: (props: any) => <DocumentHubScreen {...props} />,
  financialStatementsDocumentRequirement: (props: any) => <DocumentRequirementScreen {...props} />,
  essentialBusinessDetails: (props: any) => <GenericRequirementForm {...props} />,
  appliedForBusinessLoanScreen: (props: any) => <GenericRequirementForm {...props} />,
  loanDetails: (props: any) => <GenericRequirementForm {...props} />,
  personalFinancials: (props: any) => <GenericRequirementForm {...props} />,
  financialReferences: (props: any) => <GenericListScreen {...props} />,
  otherIncomes: (props: any) => <GenericListScreen {...props} />,
  businessAcquisitionList: (props: any) => (
    <RelationsList {...props} relation={'seller'} allowedEntityTypes={['business']} title={'Acquired Businesses'} />
  ),
  borrowers: (props: any) => (
    <RelationsList {...props} relation={'borrower'} allowedEntityTypes={['business', 'person']} showAdditionalEntitiesQuestion />
  ),
  properties: (props: any) => <PropertiesList {...props} />,
  guests: (props: any) => (
    <RelationsList {...props} relation={'guest'} allowedEntityTypes={['person']} title={'Guests'} showMarkNoEntitiesCheckbox />
  ),
  additionalGuarantors: (props: any) => (
    <RelationsList
      {...props}
      relation={'explicitGuarantor'}
      allowedEntityTypes={['person', 'business']}
      showAdditionalEntitiesQuestion
      additionalEntitiesQuestion={'Are there any non-owners who will guarantee this application?'}
      showMarkNoEntitiesCheckbox
    />
  ),
  transactionEstimates: (props: any) => <GenericListScreen {...props} />,
  firstBusinessBankDemoFinancingOptions: (props: any) => <GenericRequirementForm {...props} />,
  disclosures: (props: any) => <GenericRequirementForm {...props} />,
  onlineBanking: (props: any) => <GenericListScreen {...props} />,
  accounts: (props: any) => <GenericListScreen {...props} />,
  sourcesOfFunds: (props: any) => <GenericListScreen {...props} />,
  technologyPlatformAndSystems: (props: any) => <GenericListScreen {...props} />,
  virtualAssets: (props: any) => <GenericListScreen {...props} />,
  businessCompliant: (props: any) => <GenericRequirementForm {...props} />,
  industry: (props: any) => <GenericRequirementForm {...props} />,
  revenueAndManageExpenses: (props: any) => <GenericRequirementForm {...props} />,
  businessFinancials: (props: any) => <GenericRequirementForm {...props} />,
  additionalBusinessInformation: (props: any) => <GenericRequirementForm {...props} />,
  ssbPersonalFinancialStatement: (props: any) => <SouthStatePersonalFinancialStatementScreen {...props} />,
  startupBusinessPlan: (props: any) => <GenericRequirementForm {...props} />,
};

export const managementScreens: Screen[] = [
  'overview',
  'requestedAmount',
  'useOfFunds',
  'businessDetails',
  'servicesSelection',
  'compliance',
  'assets',
  'servicesConnection',
  'idVerification',
  'applicationSubmission',
  'signAndSubmit',
  'documentRequirement',
  'debts',
  'collateral',
  'useOfFundsBreakdown',
  'revenue',
  'financialStatements',
  'bankStatements',
  'projectedRevenue',
  'principals',
  'relatedCompanies',
  'ownedBusinesses',
  'owners',
  'personInformation',
  'realEstateDetails',
  'requestedFundingTime',
  'accountServicesSelection',
  'personalFinancialStatements',
  'w9FormSign',
  'transactionAuthorizationRules',
  'businessDescription',
  'loanDescription',
  'verification',
  'genericForm',
  'documentHub',
  'financialStatementsDocumentRequirement',
  'essentialBusinessDetails',
  'appliedForBusinessLoanScreen',
  'revenue',
  'businessAcquisitionList',
  'borrowers',
  'properties',
  'guests',
  'additionalGuarantors',
  'tbdBusinessDetails',
  'borrowingEntity',
  'personalFinancials',
  'otherIncomes',
  'financialReferences',
  'transactionEstimates',
  'disclosures',
  'onlineBanking',
  'accounts',
  'sourcesOfFunds',
  'technologyPlatformAndSystems',
  'virtualAssets',
  'businessCompliant',
  'industry',
  'revenueAndManageExpenses',
  'businessFinancials',
  'additionalBusinessInformation',
  'ssbPersonalFinancialStatement',
  'startupBusinessPlan',
];

export const defaultRegistrationScreen: Screen = 'initialDetails';
