import { useCallback, useEffect, useMemo } from 'react';
import type { PlaidLinkOptions, PlaidLinkOnSuccess } from 'react-plaid-link';
import { usePlaidLink } from 'react-plaid-link';
import { exchangePublicToken, getLinkToken } from '../shared/clients/bankService';
import { useAuthentication } from './authentication/useAuthentication';
import { usePartnerFromSubDomainQuery } from './react-query/usePartnerFromSubdomainQuery';

export const usePlaid = (onPlaidConnected: () => void, businessId?: string) => {
  const { data: partner } = usePartnerFromSubDomainQuery();
  const { token: userToken } = useAuthentication();
  const linkToken = window.sessionStorage.getItem('linkToken');
  const originPath = window.sessionStorage.getItem('originPath');

  const fetchLinkToken = useCallback(async () => {
    if (userToken && businessId && partner?.id) {
      const plaidRedirectUri = `${window.location.origin}/plaid-auth`;
      const linkTokenResponse = await getLinkToken({ businessId, redirectUri: plaidRedirectUri, partnerId: partner.id }, userToken);
      window.sessionStorage.setItem('linkToken', linkTokenResponse.linkToken);
    }
  }, [businessId, partner?.id, userToken]);

  useEffect(() => {
    if (!originPath) {
      window.sessionStorage.setItem('originPath', window.location.pathname);
    }
  });

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken: string) => {
      if (userToken && businessId && partner?.id) {
        await exchangePublicToken({ publicToken, businessId, partnerId: partner.id }, userToken);

        window.sessionStorage.removeItem('linkToken');
        window.sessionStorage.removeItem('originPath');

        onPlaidConnected();
      }
    },
    [businessId, onPlaidConnected, partner?.id, userToken],
  );

  const config: PlaidLinkOptions = useMemo(
    () => ({
      onSuccess,
      token: linkToken,
      receivedRedirectUri: window.location.href.includes('?oauth_state_id=') ? window.location.href : undefined,
    }),
    [linkToken, onSuccess],
  );

  const { open, ready } = usePlaidLink(config);

  return { open, ready, init: fetchLinkToken };
};
