import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Entity } from '@lama/common-types';
import type { FinancialData } from '@lama/contracts';
import type { FinancialUpdateModel } from '@lama/clients';
import { createFinancials, deleteFinancials, updateFinancials } from '../../shared/clients/financialsService';
import { useAuthentication } from '../authentication/useAuthentication';
// TODO: this entire mutation and types should be shared between borrower and lender

export interface FinancialUpdatePayload extends FinancialUpdateModel {
  action: 'update';
}
export interface FinancialCreatePayload extends FinancialData {
  action: 'create';
}
export interface FinancialDeletePayload {
  action: 'delete';
  id: string;
}

export type FinancialPayload = FinancialCreatePayload | FinancialDeletePayload | FinancialUpdatePayload;

export const useSubmitFinancialsMutation = (entityId: string, entityType: Entity, applicationId?: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async (financialsPayload: FinancialPayload[]) => {
      if (!isAuthenticated) {
        return;
      }

      const createPayload = financialsPayload.filter(({ action }) => action === 'create').map(({ action, ...f }) => f as FinancialData);
      const deleteFinancialIds = financialsPayload.filter(({ action }) => action === 'delete').map(({ id }) => id);
      const updatePayload = financialsPayload
        .filter(({ action }) => action === 'update')
        .map(({ action, ...f }) => f as FinancialUpdateModel);

      await Promise.all([
        ...(updatePayload.length ? [updateFinancials(entityType, entityId, updatePayload, token)] : []),
        ...(deleteFinancialIds.length ? [deleteFinancials(entityType, entityId, deleteFinancialIds, token)] : []),
        createPayload.length ? createFinancials(entityType, entityId, createPayload, token) : {},
      ]);
    },
    onSuccess: async () => {
      await Promise.all([
        [
          queryClient.invalidateQueries({
            queryKey: ['application', applicationId],
          }),
          queryClient.invalidateQueries({
            queryKey: ['applicationRequirements', applicationId],
          }),
        ],
      ]);
    },
  });
};
