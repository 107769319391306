import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip } from '@lama/app-components';
import { Text, Flex, greyPalette, Spinner } from '@lama/design-system';
import { RequirementIndication } from '../shared/RequirementIndication';
import { useUpdateApplicationRequirementMutation } from '../../hooks/react-query/useUpdateApplicationRequirementMutation';
import type { RequirementCardProps } from './RequirementCardWrapper';

export const DismissedRequirementCard: React.FC<RequirementCardProps> = ({ requirement }) => {
  const { id, name, entityId, applicationId } = requirement;
  const { mutateAsync: updateRequirement, isPending: updatingRequirement } = useUpdateApplicationRequirementMutation(applicationId, id);

  const onClick = useCallback(
    async () =>
      updateRequirement({
        updateRequirementPayload: { dataByEntity: { [entityId]: { statusOverride: null } } },
      }),

    [entityId, updateRequirement],
  );

  return (
    <Flex border={'1px solid #E0E0E0'} borderRadius={'4px'} minHeight={'56px'} alignItems={'center'} data-testid={'requirement'}>
      <Flex justifyContent={'space-between'} px={4} alignItems={'center'} width={'100%'} gap={4}>
        <Flex alignItems={'center'} gap={2}>
          <Text variant={'body1'} color={greyPalette[500]}>
            {name}
          </Text>
          {updatingRequirement ? (
            <Flex width={'40px'} height={'40px'} justifyContent={'center'} alignItems={'center'}>
              <Spinner size={'24px'} color={greyPalette[500]} />
            </Flex>
          ) : (
            <Tooltip title={'Restore'}>
              <IconButton onClick={onClick}>
                <ReplayIcon sx={{ color: greyPalette[500] }} />
              </IconButton>
            </Tooltip>
          )}
        </Flex>
        <RequirementIndication requirement={requirement} />
      </Flex>
    </Flex>
  );
};
