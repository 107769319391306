import type { FC } from 'react';
import React, { useMemo } from 'react';
import { grey } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { Text, Flex } from '@lama/design-system';
import type { ProductPublicData } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import { formatValue } from '@lama/data-formatters';
import { useIsMobile } from '@lama/app-components';
import { partnerState } from '../../state/appState';
import { SimpleHeader } from '../shared/SimpleHeader';
import { customRejectionScreens } from './customRejectionScreens';

export interface ApplicationRejectedScreenProps {
  application: ApplicationApiModel;
  product: ProductPublicData;
}

export const ApplicationRejectedScreen: FC<ApplicationRejectedScreenProps> = ({ application, product }) => {
  const isMobile = useIsMobile();
  const partner = useRecoilValue(partnerState);

  const CustomRejectionScreen = useMemo(
    () => (partner?.customRejectionScreen ? customRejectionScreens[partner?.customRejectionScreen] : null),
    [partner],
  );
  if (CustomRejectionScreen) {
    return <CustomRejectionScreen application={application} product={product} />;
  }
  return (
    <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
      <SimpleHeader showAccountSettings />
      <Flex
        flexDirection={'column'}
        gap={10}
        alignItems={'center'}
        flex={isMobile ? 1 : 0.7}
        color={grey[500]}
        py={20}
        px={isMobile ? 0 : 4}
        justifyContent={'center'}
      >
        <Flex flexDirection={'column'} gap={8} alignItems={'center'} justifyContent={'center'}>
          <Text variant={'h4'} color={'black'} textAlign={'center'}>
            {'Minimum loan requirements not met'}
          </Text>
          <Flex
            flexDirection={'column'}
            gap={4}
            justifyContent={'center'}
            borderRadius={'8px'}
            border={isMobile ? 'none' : '1px solid #E0E0E0'}
            p={isMobile ? 0 : 8}
            width={isMobile ? '85%' : '75%'}
          >
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Thank you for applying for a ${product.prettyName}${product.prettyName.includes('Loan') ? '' : ' Loan'} for ${formatValue(
                application.requestedAmount ?? 0,
                'currencyCompact',
              )}. However, we regret to advise you that we cannot grant your request at this time. Further details have been sent to your email.`}
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`${partner?.displayName}`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
