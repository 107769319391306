import React, { useCallback, useState } from 'react';
import type { ProductPublicData, Screen } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import { Navigate } from 'react-router-dom';
import { LoadingPage } from '@lama/app-components';
import { compact } from 'lodash-es';
import type { NextStepOptions } from '../../hooks/useStepsUrlNavigation';
import { useStepsUrlNavigation } from '../../hooks/useStepsUrlNavigation';
import { screenMap } from '../screensConfiguration';
import { ApplicationProvider } from '../../shared/contexts/ApplicationContext';
import { useApplicationRequirementsQuery } from '../../hooks/react-query/useApplicationRequirementsQuery';
import { isOrAfterApplicationStatus } from '../../shared/utils/isAfterApplicationStatus';
import { ErrorScreen } from '../errorScreen/ErrorScreen';
import { applicationServiceClient } from '../../shared/clients/applicationService';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';

export const FlowScreen: React.FC<{ application: ApplicationApiModel; product: ProductPublicData }> = ({ application, product }) => {
  const {
    screensConfiguration: { screenParams, screenConditions },
    onboardingRequirements,
  } = product;

  const [failingDisqualifyingConditionMessages, setFailingDisqualifyingConditionMessages] = useState<string[]>([]);

  const { data: requirements, isPending: loadingRequirements } = useApplicationRequirementsQuery(application.id);
  const { navigateNext, navigateBack, navigateSkip, currentStep } = useStepsUrlNavigation(product, application);
  const { token, isAuthenticated } = useAuthentication();

  const currentScreenName: Screen = screenMap[currentStep as Screen]
    ? (currentStep as Screen)
    : onboardingRequirements?.some((r) => r.screen === currentStep)
    ? 'genericForm'
    : 'getStarted';

  const onNextClick = useCallback(
    async (options?: NextStepOptions) => {
      if (!isAuthenticated || !token) {
        navigateNext(options);
        return;
      }

      const currentRequirement = requirements?.find((r) => r.screen === currentStep);

      const disqualifyingConditions =
        screenConditions?.[currentStep]?.disqualifyingConditionIds ?? currentRequirement?.disqualifyingConditionIds;

      if (!disqualifyingConditions?.length) {
        navigateNext(options);
        return;
      }

      const { evaluatedConditions } = await applicationServiceClient.evaluateApplication(application.id, token, disqualifyingConditions);

      const failedConditionMessages = compact(
        evaluatedConditions?.filter((condition) => condition.reason === 'fail').map(({ condition }) => condition.note),
      );

      setFailingDisqualifyingConditionMessages(failedConditionMessages);

      if (!failedConditionMessages?.length) {
        navigateNext(options);
        return;
      }
    },
    [isAuthenticated, token, currentStep, application.id, screenConditions, navigateNext, requirements],
  );

  const onBackClick = useCallback(
    (options?: NextStepOptions) => {
      setFailingDisqualifyingConditionMessages([]);
      navigateBack(options);
    },
    [navigateBack],
  );

  const Component = screenMap[currentScreenName];

  if (loadingRequirements) {
    return <LoadingPage />;
  }

  if (isOrAfterApplicationStatus(application.status, 'InReview')) {
    return <Navigate to={`/applications/${application.id}/overview`} />;
  }

  if (!requirements) {
    return <ErrorScreen />;
  }

  return (
    <ApplicationProvider application={application} product={product} requirements={requirements}>
      <Component
        {...screenParams[currentStep]}
        flow={'onboarding'}
        onNextClick={onNextClick}
        onBackClick={onBackClick}
        saveEnabled
        failingDisqualifyingConditionMessages={failingDisqualifyingConditionMessages}
        onSkip={navigateSkip}
      />
    </ApplicationProvider>
  );
};
