import React, { useCallback, useState } from 'react';
import { ToggleButton } from '@mui/material';
import { useIsMobile } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import type { Relation } from '@lama/contracts';
import type { ApplicationApiModel, ApplicationUpdateApiModel } from '@lama/clients';
import { isNil } from 'lodash-es';
import type { ScreenProps } from '../../ScreenProps';
import { BasicScreen } from '../../shared/BasicScreen';
import { MultiPeopleIcon } from './MultiPeople';
import { SingleIcon } from './Single';

export type RelationEntitiesQuantityType = 'MoreEntities' | 'NoAdditional' | 'NoEntities';

interface AdditionalEntitiesQuestionProps extends ScreenProps {
  additionalEntitiesQuestion?: string;
  application: ApplicationApiModel;
  updateApplication: (payload: { updateApplicationPayload: ApplicationUpdateApiModel }) => Promise<void>;
  relation: Relation;
  onSelectedAnswer: (value: boolean) => void;
}

export const AdditionalEntitiesToRelationQuestion: React.FC<AdditionalEntitiesQuestionProps> = ({
  additionalEntitiesQuestion,
  application,
  relation,
  onSelectedAnswer,
  updateApplication,
  onNextClick = () => {},
  onBackClick,
  flow,
  ...stepsNavigationProps
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<boolean | null>(null);
  const isMobile = useIsMobile();

  const onClick = useCallback(
    async (_e: any, value: boolean) => {
      setSelectedAnswer(value);
      onSelectedAnswer(value);
    },
    [onSelectedAnswer],
  );

  return (
    <BasicScreen
      {...stepsNavigationProps}
      onSaveClick={onNextClick}
      onBackClick={onBackClick}
      flow={flow}
      saveEnabled={!isNil(selectedAnswer)}
    >
      <Flex flexDirection={'column'} gap={8}>
        <Text variant={'h6'} color={'secondary'} textAlign={'center'}>
          {additionalEntitiesQuestion}
        </Text>
        <Flex flexDirection={isMobile ? 'column' : 'row'} gap={6} pb={isMobile ? 0 : 40} justifyContent={'center'}>
          <ToggleButton
            value
            color={'primary'}
            onClick={onClick}
            selected={!!selectedAnswer}
            sx={{
              padding: isMobile ? 2 : 3,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&.Mui-selected': {
                borderColor: 'primary.main',
              },
              width: isMobile ? '100%' : '236px',
            }}
            fullWidth
            key={'yes'}
          >
            <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'space-between'} gap={4}>
              <MultiPeopleIcon selected={!!selectedAnswer} />
              <Text variant={'body1'}>{'Yes'}</Text>
            </Flex>
          </ToggleButton>
          <ToggleButton
            value={false}
            color={'primary'}
            onClick={onClick}
            selected={selectedAnswer === false}
            sx={{
              padding: isMobile ? 2 : 3,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&.Mui-selected': {
                borderColor: 'primary.main',
              },
              width: isMobile ? '100%' : '236px',
            }}
            fullWidth
            key={'no'}
          >
            <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'space-between'} gap={4}>
              <SingleIcon selected={selectedAnswer === false} />
              <Text variant={'body1'}>{'No'}</Text>
            </Flex>
          </ToggleButton>
        </Flex>
      </Flex>
    </BasicScreen>
  );
};
