import { useMutation, useQueryClient } from '@tanstack/react-query';

import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useInviteToApplication = (applicationId: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async ({ personId, skipInviteEmail = false }: { personId: string; skipInviteEmail?: boolean }) => {
      if (isAuthenticated) {
        await applicationServiceClient.invitePersonToApplication({ applicationId, personId, skipInviteEmail }, token);
      }

      return { skipInviteEmail };
    },
    onSuccess: async ({ skipInviteEmail }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['application', applicationId],
        }),
      ]);

      if (!skipInviteEmail) {
        displayToast('Invitation sent', 'success', {
          toastId: 'inviteToApplication',
        });
      }
    },
    onError: () => {
      displayToast('Something went wrong while inviting to the application. Please contact support.', 'error', {
        toastId: 'inviteToApplication',
      });
    },
  });
};
