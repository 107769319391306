import React, { useCallback } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { Link, Typography } from '@mui/material';
import { noCase } from 'change-case';
import { Flex } from '@lama/design-system';
import { BasicScreen } from '../shared/BasicScreen';
import { DocumentRequirement } from '../shared/genericRequirements/documentRequirement/DocumentRequirement';
import type { Flow } from '../ScreenProps';

interface UploadBankStatementsProps {
  requirement: EvaluatedApplicationRequirement;
  setFillingOption: (option: 'auto' | 'manual') => void;
  flow: Flow;
}

export const UploadBankStatements: FC<UploadBankStatementsProps> = ({ requirement, setFillingOption, flow }) => {
  const { t } = useTranslation();

  const onConnect = useCallback(() => {
    setFillingOption('auto');
  }, [setFillingOption]);

  return (
    <BasicScreen flow={flow} title={requirement.name} subtitle={requirement.description} backVisible={false} saveVisible={false}>
      <Flex flexDirection={'column'} alignItems={'center'} gap={8}>
        <DocumentRequirement requirement={requirement} />
        <Typography variant={'subtitle2'} color={'text.secondary'}>
          {'Or'}{' '}
          <Link onClick={onConnect} variant={'subtitle2'} color={'primary.main'} sx={{ cursor: 'pointer' }}>
            {noCase(t('bankStatements.connect'))}
          </Link>
        </Typography>
      </Flex>
    </BasicScreen>
  );
};
