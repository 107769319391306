/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import { useIsMobile, LoadingPage } from '@lama/app-components';
import { useQueryClient } from '@tanstack/react-query';
import { BasicScreen } from '../shared/BasicScreen';
import type { Account } from '../shared/ConnectedAccountsView';
import { ConnectedAccountsView } from '../shared/ConnectedAccountsView';
import { NoConnectedAccountsView } from '../shared/NoConnectedAccountsView';
import { disableConnection, getConnections } from '../../shared/clients/accountingService';
import { RailzDialog } from '../shared/railz/RailzDialog';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import type { ScreenProps } from '../ScreenProps';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useDocumentsQuery } from '../../hooks/react-query/useDocumentsQuery';
import { isDocumentRelatedToRequirement } from '../../shared/utils/isDocumentRelatedToRequirement';
import { UploadFinancialStatements } from './UploadFinancialStatements';
import { FinancialStatementsIcon } from './FinancialStatementslcon.js';

const getActiveAccounts = async (businessId: string, token: string) => {
  const connections = await getConnections(businessId, token);

  return connections
    .filter(({ status }) => status === 'active')
    .map((c) => ({
      id: c.connectionId,
      name: [c.serviceName[0], c.serviceName[1]],
      title: c.serviceName,
      logo: c.institution?.logo,
    }));
};

const useRailzAccounts = (businessId?: string) => {
  const { isAuthenticated, token } = useAuthentication();
  const [accounts, setAccounts] = useState<Account[]>([]);

  const [{ loading: fetchingAccounts }, fetchAccounts] = useAsyncFn(async () => {
    if (isAuthenticated && businessId) {
      const activeAccounts = await getActiveAccounts(businessId, token);
      setAccounts(activeAccounts);
    }
  }, [businessId, isAuthenticated, token]);

  const [{ loading: disablingConnection }, deleteAccount] = useAsyncFn(
    async (accountId: string) => {
      if (isAuthenticated && businessId) {
        await disableConnection(businessId, accountId, token);
        await fetchAccounts();
      }
    },
    [businessId, fetchAccounts, isAuthenticated, token],
  );

  useEffect(() => {
    if (businessId) {
      void fetchAccounts();
    }
  }, [businessId, fetchAccounts]);

  return { accounts, loading: fetchingAccounts || disablingConnection, deleteAccount, fetchAccounts };
};

export const FinancialStatementsScreen: FC<ScreenProps> = (props) => {
  const requirement = useGetCurrentRequirement();
  const { flow } = props;
  const { application } = useContext(ApplicationContext);
  const relevantBusiness = useMemo(
    () => application.relatedBusinesses.find(({ business }) => business.id === requirement?.entityId)?.business,
    [application, requirement],
  );

  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fillingOption, setFillingOption] = useState<'auto' | 'manual' | null>(null);
  const [open, setOpen] = useState(false);
  const { accounts, loading, deleteAccount, fetchAccounts } = useRailzAccounts(relevantBusiness?.id);

  const { data: documents, isPending: loadingDocuments } = useDocumentsQuery(application.id);

  const relatedDocuments = useMemo(
    () => (requirement && documents?.length ? documents.filter((doc) => isDocumentRelatedToRequirement(doc, requirement)) : []),
    [requirement, documents],
  );
  const queryClient = useQueryClient();

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(async () => {
    await fetchAccounts();
    await queryClient.invalidateQueries({
      queryKey: ['applicationRequirements', application.id],
    });
    setOpen(false);
  }, [application.id, fetchAccounts, queryClient]);

  if (!requirement) {
    navigate('../overview');
    return null;
  }

  if (loading || loadingDocuments) {
    return <LoadingPage />;
  }

  if (fillingOption === 'manual' || (relatedDocuments?.length && fillingOption === null)) {
    return <UploadFinancialStatements requirement={requirement} setFillingOption={setFillingOption} flow={flow} />;
  }

  if (!relevantBusiness) {
    return null;
  }

  return (
    <BasicScreen
      {...props}
      title={t('financialStatements.title')}
      subtitle={isMobile ? t('financialStatements.mobile.subtitle') : t('financialStatements.subtitle')}
      backVisible={false}
      saveVisible={false}
    >
      {accounts.length ? (
        <ConnectedAccountsView accounts={accounts} deleteAccount={deleteAccount} />
      ) : (
        <NoConnectedAccountsView
          connectIcon={<CurrencyExchangeRoundedIcon />}
          connectText={isMobile ? t('financialStatements.mobile.connect') : t('financialStatements.connect')}
          emptyLogo={<FinancialStatementsIcon />}
          connectAccount={openDialog}
          setFillingOption={setFillingOption}
        />
      )}
      <RailzDialog businessId={relevantBusiness.id} open={open} onClose={closeDialog} />
    </BasicScreen>
  );
};
