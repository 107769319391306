import type { ExchangePublicTokenBody, GetAccountsQueryString, GetLinkTokenQueryString } from '@lama/clients';
import { createBankServiceClient } from '@lama/clients';
import { bankServiceBaseUrl } from '../../framework/environment';

export const bankServiceClient = createBankServiceClient(bankServiceBaseUrl as string);

export const getLinkToken = (queryString: GetLinkTokenQueryString, userToken: string) =>
  bankServiceClient.getLinkToken(queryString, userToken);

export const exchangePublicToken = (body: ExchangePublicTokenBody, token: string) => bankServiceClient.exchangePublicToken(body, token);

export const getAccounts = (queryString: GetAccountsQueryString, token: string) => bankServiceClient.getAccounts(queryString, token);
