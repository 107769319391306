/* eslint-disable @typescript-eslint/naming-convention */
import { displayToast, LoadingPage } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import type { SxProps, Theme } from '@mui/material';
import { Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { MuiOtpInput } from 'mui-one-time-password-input';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendOtpMutation } from '../../../hooks/react-query/useSendOtpMutation';
import { useVerifyOtpMutation } from '../../../hooks/react-query/useVerifyOtpMutation';

const hideNumberSpinnersSx: SxProps<Theme> = {
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

interface VerifyOtpComponentProps {
  connection: 'email' | 'sms';
  to: string;
  onOtpVerified?: (token: string) => void;
}

export const VerifyOtpComponent: FC<VerifyOtpComponentProps> = ({ connection, to, onOtpVerified }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [otp, setOtp] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);

  const { mutateAsync: sendOtp } = useSendOtpMutation();
  const { mutate: verifyOtp, isPending: verifyingOtp } = useVerifyOtpMutation({
    onSuccess: async ({ token }) => {
      setOtpVerified(true);
      onOtpVerified?.(token);
    },
    onError: (error) => {
      setOtp('');
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        displayToast(t('verification.notification.otpVerifyError'), 'error');
        return;
      }
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });

  const onOtpChange = useCallback((newValue: string) => {
    setOtp(newValue);
  }, []);

  const onResendOtpClick = useCallback(async () => {
    await sendOtp({ sendOtpRequest: { connection, to } });
  }, [connection, sendOtp, to]);

  const onOtpComplete = useCallback(
    async (value: string) => {
      verifyOtp({ verifyOtpRequest: { connection, otp: value, to } });
    },
    [connection, to, verifyOtp],
  );

  return (
    <Flex flexDirection={'column'} alignItems={'center'} gap={8}>
      <Text variant={'body1'}>{t('verification.subtitle.verifyOtp', { destination: to })}</Text>
      <MuiOtpInput
        value={otp}
        length={6}
        onChange={onOtpChange}
        onComplete={onOtpComplete}
        TextFieldsProps={{
          sx: { maxWidth: '56px', ...hideNumberSpinnersSx },
          type: 'number',
          inputProps: {
            inputMode: 'numeric',
            pattern: '[0-9]*',
          },
          disabled: verifyingOtp || otpVerified,
        }}
        gap={2}
      />
      {verifyingOtp || otpVerified ? (
        <LoadingPage />
      ) : (
        <Typography variant={'caption'}>
          {t('verification.resend.prefix')}{' '}
          <Typography variant={'caption'} color={theme.palette.primary.main} sx={{ cursor: 'pointer' }} onClick={onResendOtpClick}>
            {t('verification.resend.cta')}
          </Typography>
        </Typography>
      )}
    </Flex>
  );
};
