import { ChevronRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useIsMobile } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ExistingUserLoginHint } from '../shared/ExistingUserLoginHint';
import { useBrandOverrides } from '../../hooks/useBrandOverrides';
import { GetStartedImage } from './GetStartedImage';

interface GetStartedScreenProps extends ScreenProps {
  title?: string;
  subtitle?: string;
  cta?: string;
}

export const GetStartedScreen: React.FC<GetStartedScreenProps> = ({
  onNextClick,
  title,
  subtitle,
  cta,
  saveLoading,
  ...stepsNavigationProps
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleNextClick = useCallback(() => {
    onNextClick();
  }, [onNextClick]);
  const overrides = useBrandOverrides();

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={title}
      subtitle={overrides?.getStartedSubtitleOverride ?? subtitle ?? 'A fast, 4-step process to secure the funding you need'}
      backVisible={false}
      saveVisible={false}
      animate={false}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GetStartedImage />
      </Box>
      <Flex flexDirection={'column'} gap={4} alignItems={'center'} justifyContent={'center'} pt={4}>
        <LoadingButton
          loading={saveLoading}
          endIcon={<ChevronRight />}
          size={'large'}
          variant={'contained'}
          color={'primary'}
          onClick={handleNextClick}
          sx={{ width: isMobile ? '100%' : '296px', height: '40px' }}
        >
          {cta || t('getStarted.cta')}
        </LoadingButton>
        <ExistingUserLoginHint />
      </Flex>
    </BasicScreen>
  );
};
