import React from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { BasicScreen } from '../shared/BasicScreen';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { DocumentRequirement } from '../shared/genericRequirements/documentRequirement/DocumentRequirement';
import type { ScreenProps } from '../ScreenProps';

export const DocumentRequirementScreen: FC<ScreenProps> = ({ flow, ...props }) => {
  const requirement = useGetCurrentRequirement();

  if (!requirement) {
    return null;
  }

  return (
    <BasicScreen {...props} flow={flow} title={requirement.name} subtitle={requirement?.description} saveVisible={flow === 'onboarding'}>
      <Flex flexDirection={'column'} gap={10} alignItems={'center'}>
        <DocumentRequirement requirement={requirement} />
      </Flex>
    </BasicScreen>
  );
};
