import React, { useCallback } from 'react';
import { Container } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Flex } from '@lama/design-system';
import { useQueryClient } from '@tanstack/react-query';
import { BasicScreen } from '../shared/BasicScreen';
import { MainHeader } from '../../applicationFormComponents/MainHeader';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import { useQueryParams } from '../../hooks/useQueryParams';
import { OtpVerification } from '../shared/OtpVerification/OtpVerification';

export const LoginScreen: React.FC = () => {
  const { email } = useQueryParams(['email']);
  const queryClient = useQueryClient();

  const { isAuthenticated, setAccessToken } = useAuthentication();

  const onOtpVerified = useCallback(
    async (token: string) => {
      setAccessToken(token);
      await queryClient.resetQueries();
    },
    [queryClient, setAccessToken],
  );

  if (isAuthenticated) {
    return <Navigate to={'/'} />;
  }

  return (
    <Flex flexDirection={'column'}>
      <MainHeader showAccountSettings={false} />
      <Container maxWidth={'md'} sx={{ py: 6 }}>
        <BasicScreen flow={'onboarding'} title={'Login'} backVisible={false} saveVisible={false}>
          <OtpVerification onOtpVerified={onOtpVerified} initialEmail={email} />
        </BasicScreen>
      </Container>
    </Flex>
  );
};
