import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import type { FinancialData } from '@lama/contracts';
import { getApplicationEntityByType } from '@lama/properties';
import { mapValues } from 'lodash-es';
import { ConfirmLeave, FormikDatePicker, FormikMoneyInputField } from '@lama/app-components';
import type { FormikHelpers } from 'formik';
import { Formik, type FormikProps } from 'formik';
import * as yup from 'yup';
import { Flex, Grid, Text } from '@lama/design-system';
import { getYear } from '@lama/data-formatters';
import type { PersonApiModel } from '@lama/clients';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { useSubmitFinancialsMutation } from '../../hooks/react-query/useSubmitFinancialsMutation';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { useUpdatePerson } from '../../hooks/react-query/useUpdatePerson';
import { getFinancialPayload } from './financialFieldUtils';

const assetsFieldNamesToFinancialAttribute: Record<string, { financialAttribute: string; displayName: string }> = {
  cash: { financialAttribute: 'Cash in Bank Accounts', displayName: 'Personal Cash' },
  securities: { financialAttribute: 'Marketable Securities', displayName: 'Personal Stocks & Bonds' },
  insurance: { financialAttribute: 'Life Insurance - Cash Surrender Value Only', displayName: 'Cash Surrender Value of Life Insurance' },
  personalIra: { financialAttribute: 'Personal IRA or Other Retirement Accounts', displayName: 'Retirement Accounts' },
  realEstateAssets: { financialAttribute: 'Personal Real Estate', displayName: 'Real Estate Owned' },
  otherAssets: { financialAttribute: 'Other Personal Assets', displayName: 'Other Assets' },
};

const liabilitiesFieldNamesToFinancialAttribute: Record<string, { financialAttribute: string; displayName: string }> = {
  creditCards: { financialAttribute: 'Personal Accounts Payable', displayName: 'Credit Cards' },
  installments: { financialAttribute: 'Other Personal Installment Accounts', displayName: 'Installment Loans' },
  realEstateLoans: { financialAttribute: 'Personal Mortgages on Real Estate', displayName: 'Real Estate Loans' },
  taxes: { financialAttribute: 'Personal Unpaid Taxes', displayName: 'Taxes Owed' },
  otherLiabilities: { financialAttribute: 'Other Personal Liabilities', displayName: 'Other Liabilities' },
};

interface SouthStatePersonalFinancialStatementScreenInnerFormProps {
  formikProps: FormikProps<any>;
}

const SouthStatePersonalFinancialStatementScreenInnerForm: FC<SouthStatePersonalFinancialStatementScreenInnerFormProps> = ({
  formikProps: { dirty },
}) => (
  <ConfirmLeave shouldBlock={dirty}>
    <Flex flexDirection={'column'} gap={8}>
      <Flex flexDirection={'column'} gap={4}>
        <Flex width={'20%'}>
          <FormikDatePicker name={'asOf'} label={'As of'} />
        </Flex>
        <Text variant={'h6'}>{'Assets'} </Text>
        <Grid columns={3}>
          {Object.entries(assetsFieldNamesToFinancialAttribute).map(([fieldName, { displayName }]) => (
            <FormikMoneyInputField key={fieldName} name={fieldName} label={displayName} />
          ))}
        </Grid>
      </Flex>
      <Flex flexDirection={'column'} gap={4}>
        <Text variant={'h6'}>{'Liabilities'} </Text>
        <Grid columns={3}>
          {Object.entries(liabilitiesFieldNamesToFinancialAttribute).map(([fieldName, { displayName }]) => (
            <FormikMoneyInputField key={fieldName} name={fieldName} label={displayName} />
          ))}
        </Grid>
      </Flex>
    </Flex>
  </ConfirmLeave>
);

export const getFinancialFieldByYear = (financials: FinancialData[], year: number, fieldName: string) => {
  const fieldFinancials = financials.filter(
    (financial) => getYear(financial.startDate) === year && getYear(financial.endDate) === year && financial.type === fieldName,
  );

  return fieldFinancials.find(({ source }) => source.type === 'Manual');
};

export const SouthStatePersonalFinancialStatementScreen: FC<ScreenProps> = ({
  onNextClick,
  onBackClick,
  saveEnabled,
  ...stepsNavigationProps
}) => {
  const { application } = useContext(ApplicationContext);

  const user = useContext(UserDetailsContext);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const { mutateAsync: updatePerson, isPending: isUpdatingPerson } = useUpdatePerson(application.id);

  const requirement = useGetCurrentRequirement();

  const { isPending: savingFinancials, mutateAsync: updateFinancialData } = useSubmitFinancialsMutation(
    requirement?.entityId ?? '',
    'person',
    application.id,
  );

  const person = useMemo(
    () =>
      getApplicationEntityByType(application, 'person', ['all']).find(({ id }) => id === requirement?.entityId) as
        | PersonApiModel
        | undefined,
    [application, requirement?.entityId],
  );

  const validationSchema = useMemo(() => yup.object(mapValues(assetsFieldNamesToFinancialAttribute, () => yup.number().nullable())), []);

  const initialValues = useMemo(
    () => ({
      ...mapValues(
        { ...assetsFieldNamesToFinancialAttribute, ...liabilitiesFieldNamesToFinancialAttribute },
        ({ financialAttribute }) =>
          getFinancialFieldByYear(person?.financials ?? [], application.leadingReferenceYear, financialAttribute)?.value?.toString() ?? '',
      ),
      asOf: person?.personalFinancialStatementAsOfDate ?? '',
    }),
    [person, application.leadingReferenceYear],
  );

  const submit = useCallback(
    async (newValues: typeof initialValues, { resetForm }: FormikHelpers<any>) => {
      resetForm({ values: newValues });

      if (!user) {
        return;
      }
      const { id: userId } = user;

      const financialsPayload = Object.entries(newValues)
        .filter(([fieldName]) => fieldName !== 'asOf')
        .flatMap(([fieldName, value]) =>
          getFinancialPayload({
            entityId: requirement?.entityId ?? '',
            financials: person?.financials ?? [],
            year: application.leadingReferenceYear,
            fieldName:
              (assetsFieldNamesToFinancialAttribute[fieldName]?.financialAttribute ||
                liabilitiesFieldNamesToFinancialAttribute[fieldName]?.financialAttribute) ??
              '',
            value,
            userId,
            startDate: `${application.leadingReferenceYear}-01-01T00:00:00Z`,
            endDate: `${application.leadingReferenceYear}-12-31T00:00:00Z`,
          }),
        );

      if (financialsPayload.length) {
        await updateFinancialData(financialsPayload);
      }

      if (newValues.asOf && person?.id) {
        await updatePerson({ personId: person.id, updatePersonPayload: { personalFinancialStatementAsOfDate: newValues.asOf } });
      }

      setSubmittedSuccessfully(true);
    },
    [user, person?.id, person?.financials, requirement?.entityId, application.leadingReferenceYear, updateFinancialData, updatePerson],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {(formikProps) => (
        <BasicScreen
          title={'Stated Personal Financial Statement'}
          onSaveClick={formikProps.submitForm}
          onBackClick={onBackClick}
          saveLoading={savingFinancials || isUpdatingPerson}
          saveEnabled={!!saveEnabled || (formikProps.dirty && formikProps.isValid)}
          {...stepsNavigationProps}
          successSubmit={submittedSuccessfully}
        >
          <SouthStatePersonalFinancialStatementScreenInnerForm formikProps={formikProps} />
        </BasicScreen>
      )}
    </Formik>
  );
};
