import { useCallback, useContext, useMemo } from 'react';
import { isNil } from 'lodash-es';
import { paramCase } from 'change-case';
import { useLocation, useNavigate } from 'react-router-dom';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { getRequirementsByCategory } from '@lama/app-components';
import { ApplicationContext } from '../../../shared/contexts/ApplicationContext';
import { useGetCurrentRequirement } from '../../../hooks/useGetCurrentRequirement';

export const useManagementNavigation = () => {
  const applicationContext = useContext(ApplicationContext);
  const { requirements, application } = applicationContext ?? {};
  const currentRequirement = useGetCurrentRequirement();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const orderedPendingRequirements = useMemo(
    () =>
      getRequirementsByCategory(requirements, application).flatMap((category) =>
        category.requirements.filter(({ status }) => status === 'Pending'),
      ),
    [requirements, application],
  );

  const currentRequirementIndex = useMemo(
    () => orderedPendingRequirements?.findIndex(({ id }) => id === currentRequirement?.id),
    [currentRequirement, orderedPendingRequirements],
  );

  const navigateToRequirement = useCallback(
    (requirement: EvaluatedApplicationRequirement) => {
      if (!requirement) {
        return;
      }

      navigate(`${pathname}/../${paramCase(requirement.screen)}?requirementId=${requirement.id}`);
    },
    [navigate, pathname],
  );

  const goNext = useCallback(() => {
    navigateToRequirement(orderedPendingRequirements[currentRequirementIndex + 1]);
  }, [currentRequirementIndex, orderedPendingRequirements, navigateToRequirement]);

  const goBack = useCallback(() => {
    navigateToRequirement(orderedPendingRequirements[currentRequirementIndex - 1]);
  }, [currentRequirementIndex, orderedPendingRequirements, navigateToRequirement]);

  if (!applicationContext) {
    return null;
  }

  if (isNil(currentRequirementIndex)) {
    return {
      goBack,
      goNext,
      backEnabled: false,
      nextEnabled: false,
    };
  }

  return {
    goBack,
    goNext,
    backEnabled: currentRequirementIndex > 0,
    nextEnabled: currentRequirementIndex < orderedPendingRequirements?.length - 1,
  };
};
