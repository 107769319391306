/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { YesNoQuestion } from '@lama/app-components';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash-es';
import { applicationBorrowingBusinessSelector } from '@lama/data-formatters';
import type { ApplicationApiModel } from '@lama/clients';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useCreateBusinessMutation } from '../../hooks/react-query/useCreateBusinessMutation';
import { useAddApplicationRelation } from '../../hooks/react-query/useAddApplicationRelation';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';

const businessIsAlreadySeller = (application: ApplicationApiModel, businessId: string) =>
  application.relatedBusinesses?.some(
    ({ business: relatedBusiness, relations }) => relatedBusiness.id === businessId && relations.includes('seller'),
  );

export const BorrowingEntityScreen: React.FC<ScreenProps> = ({ onNextClick, onBackClick, saveEnabled, ...stepsNavigationProps }) => {
  const { application } = useContext(ApplicationContext);
  const business = useMemo(() => applicationBorrowingBusinessSelector(application), [application]);
  const [usingSameTin, setUsingSameTin] = useState<boolean | null>(null);
  const [alreadyFormed, setAlreadyFormed] = useState<boolean | null>(!isNil(business?.unformed) ? !business?.unformed : null);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updateBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: addApplicationRelation } = useAddApplicationRelation();

  const shouldSkipUsingSameTin = useMemo(() => !!application.useOfFunds?.includes('Startup'), [application]);

  const onSubmit = useCallback(async () => {
    let businessId;
    const unformed = usingSameTin ? false : !alreadyFormed;
    if (business) {
      await updateBusiness({ businessId: business.id, updateBusinessPayload: { unformed, excludedFromFinancialCalculations: true } });
      businessId = business.id;
    } else {
      businessId = uuidv4();
      await createBusiness({
        business: {
          applicationId: application.id,
          partnerId: application.originatingPartner,
          id: businessId,
          unformed,
          excludedFromFinancialCalculations: true,
          people: [],
        },
        applicationId: application.id,
      });
      await addApplicationRelation({
        applicationId: application.id,
        relation: 'borrower',
        entityId: businessId,
        entityType: 'business',
      });
    }

    if (usingSameTin && !businessIsAlreadySeller(application, businessId)) {
      await addApplicationRelation({
        applicationId: application.id,
        relation: 'seller',
        entityId: businessId,
        entityType: 'business',
      });
    }

    onNextClick({ businessUnformed: unformed });
  }, [alreadyFormed, business, usingSameTin, application, onNextClick, updateBusiness, createBusiness, addApplicationRelation]);

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={'Borrowing Entity'}
      onSaveClick={onSubmit}
      saveEnabled={!!saveEnabled && (shouldSkipUsingSameTin || usingSameTin || !isNil(alreadyFormed))}
      onBackClick={onBackClick}
      saveLoading={creatingBusiness}
    >
      {!shouldSkipUsingSameTin ? (
        <YesNoQuestion
          name={'sameTin'}
          value={usingSameTin}
          question={'Will the business continue operating under the existing tax identification number?'}
          onChange={setUsingSameTin}
        />
      ) : null}
      {shouldSkipUsingSameTin || usingSameTin === false ? (
        <YesNoQuestion
          name={'alreadyFormed'}
          value={alreadyFormed}
          question={'Is the borrowing entity already formed?'}
          onChange={setAlreadyFormed}
        />
      ) : null}
    </BasicScreen>
  );
};
