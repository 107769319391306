import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Lock, Shield, VpnLock } from '@mui/icons-material';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { useRecoilValue } from 'recoil';
import { partnerState } from '../state/appState';

export const HostingDisclaimer: FC<{ partnerDisplayName: string }> = ({ partnerDisplayName }) => (
  <Text variant={'body3'} textAlign={'center'} color={greyPalette[700]} letterSpacing={'normal'} maxWidth={'100%'}>
    {'This service is hosted by '}
    <Link target={'_blank'} to={'https://www.lamabanking.com'} color={'primary.main'}>
      {'lamabanking.com'}
    </Link>
    {` on behalf of ${partnerDisplayName}. `}
  </Text>
);

const FDICLogos: FC = () => (
  <Flex gap={5} height={'36px'} alignItems={'center'}>
    <img width={'auto'} height={'90%'} src={'/logos/footer-logos-fdic.svg'} alt={'fdic logo'} />
    <img width={'auto'} height={'100%'} src={'/logos/footer-logos-ehl.svg'} alt={'ehl logo'} />
  </Flex>
);

export const Footer: React.FC<{ isFirstStep: boolean }> = ({ isFirstStep }) => {
  const partner = useRecoilValue(partnerState);

  const termsOfUseUrl = useMemo(
    () => (partner ? `https://lama-ai-assets.s3.us-east-1.amazonaws.com/partner-terms-of-use/${partner.name}/TermsOfUse.pdf` : ''),
    [partner],
  );

  const showHostingDisclaimer = useMemo(
    () => partner?.status === 'production' && window.location.hostname.includes('lamabanking'),
    [partner],
  );

  if (!partner) {
    return null;
  }

  if (!isFirstStep) {
    return (
      <Flex flexDirection={'column'} px={2} pt={16} pb={16} gap={4} alignItems={'center'}>
        <Flex gap={4}>
          <Lock color={'primary'} />
          <Shield color={'primary'} />
          <VpnLock color={'primary'} />
        </Flex>
        {showHostingDisclaimer ? <HostingDisclaimer partnerDisplayName={partner.displayName} /> : null}
      </Flex>
    );
  }

  return (
    <Flex flexDirection={'column'} pb={8} gap={2} alignItems={'center'}>
      {partner?.featureConfigurations?.displayFDICInsuredLogo ? <FDICLogos /> : null}
      <Flex flexDirection={'column'} alignItems={'center'}>
        {showHostingDisclaimer ? <HostingDisclaimer partnerDisplayName={partner.displayName} /> : null}
        <Text variant={'body3'} textAlign={'center'} color={greyPalette[700]} letterSpacing={'normal'}>
          {'By continuing you agree to the '}
          <Link target={'_blank'} to={termsOfUseUrl} color={'primary.main'}>
            {'Terms of Use'}
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
};
