import { Flex, Text } from '@lama/design-system';
import { Container, Paper, TextField, Button } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Md5 } from 'ts-md5';
import { useRecoilValue } from 'recoil';
import { HostingDisclaimer } from '../../applicationFormComponents/Footer';
import { partnerState } from '../../state/appState';

const expectedPasswordHashed = '79df32ce1b812c79888dbd5e9e01bbf7';

export const DemoLoginPage: FC<{ setAccess: (val: boolean) => void }> = ({ setAccess }) => {
  const partner = useRecoilValue(partnerState);
  const [password, setPassword] = useState('');

  const onSubmitClick = useCallback((): void => {
    if (Md5.hashStr(password) === expectedPasswordHashed) {
      setAccess(true);
      return;
    }

    setAccess(false);
    setPassword('');
  }, [password, setAccess]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        onSubmitClick();
      }
    },
    [onSubmitClick],
  );

  const onPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  }, []);

  if (!partner) {
    return null;
  }

  return (
    <Container>
      <Flex flexDirection={'column'} width={'100%'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <Paper elevation={12}>
          <Flex flexDirection={'column'} gap={8} p={8}>
            <Flex flexDirection={'column'} gap={2}>
              <Text variant={'h4'}>{'Welcome!'}</Text>
              <Text variant={'h6'}>{'Please enter your access code'}</Text>
              <HostingDisclaimer partnerDisplayName={partner.displayName} />
            </Flex>
            <Flex flexDirection={'column'} gap={4}>
              <TextField
                size={'small'}
                label={'Password'}
                type={'password'}
                value={password}
                onChange={onPasswordChange}
                onKeyDown={onKeyDown}
                autoFocus
              />
              <Button variant={'outlined'} onClick={onSubmitClick}>
                {'Submit'}
              </Button>
            </Flex>
          </Flex>
        </Paper>
      </Flex>
    </Container>
  );
};
