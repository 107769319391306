import React, { useCallback, useContext, useMemo } from 'react';
import { BasePrincipalsScreen, SearchExistingCustomersProvider } from '@lama/app-components';
import { useRecoilState } from 'recoil';
import { initiator, relatedBusinessesByRelationSelector } from '@lama/data-formatters';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUpdateApplication } from '../../hooks/react-query/useUpdateApplication';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { useInviteToApplication } from '../../hooks/react-query/useInviteToApplication';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useUpdatePerson } from '../../hooks/react-query/useUpdatePerson';
import { useCreatePerson } from '../../hooks/react-query/useCreatePerson';
import { useCreateBusinessMutation } from '../../hooks/react-query/useCreateBusinessMutation';
import { usePartnerFromSubDomainQuery } from '../../hooks/react-query/usePartnerFromSubdomainQuery';
import { relationToBusinessState } from '../../state/applicationState';

interface OwnersScreenProps extends ScreenProps {
  title?: string;
}

export const OwnersScreen: React.FC<OwnersScreenProps> = ({
  flow,
  onNextClick,
  onBackClick,
  saveLoading,
  title,
  ...stepsNavigationProps
}) => {
  const { application } = useContext(ApplicationContext);
  const [relationToBusiness, setRelationToBusiness] = useRecoilState(relationToBusinessState);

  const partner = usePartnerFromSubDomainQuery();
  const requirement = useGetCurrentRequirement();
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplication(application.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(application.id);
  const { mutateAsync: createPerson, isPending: creatingPerson } = useCreatePerson({ applicationId: application.id });
  const { mutateAsync: inviteToApplication } = useInviteToApplication(application.id);

  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement?.entityId)?.business,
    [application.relatedBusinesses, requirement?.entityId],
  );

  const initiatorPerson = useMemo(() => initiator(application), [application]);
  const borrowersBusinesses = useMemo(
    () => relatedBusinessesByRelationSelector(application, 'borrower').map((rb) => rb.business),
    [application],
  );

  const loading = useMemo(
    () => updatingApplication || updatingBusiness || creatingBusiness || saveLoading || updatingPerson || creatingPerson,
    [creatingPerson, creatingBusiness, saveLoading, updatingApplication, updatingBusiness, updatingPerson],
  );

  const minRequiredOwnershipPercentage = useMemo(
    () => (requirement?.properties.some((p) => p.topic === 'fullPrincipals') ? 100 : 60),
    [requirement?.properties],
  );

  const onPrincipalRemoved = useCallback(
    (principalId: string, type: 'business' | 'person') => {
      if (
        flow === 'onboarding' &&
        type === 'person' &&
        principalId === initiatorPerson?.id &&
        business &&
        relationToBusiness !== 'OnBehalfOfOwners'
      ) {
        const isInitiatorOwnerOfAnotherBusiness = borrowersBusinesses
          .filter(({ id }) => id !== business.id)
          .some(({ people }) => people.some(({ id }) => id === initiatorPerson.id));

        if (!isInitiatorOwnerOfAnotherBusiness) {
          setRelationToBusiness('OnBehalfOfOwners');
        }
      }
    },
    [borrowersBusinesses, flow, initiatorPerson, relationToBusiness, setRelationToBusiness, business],
  );

  if (!business) {
    return null;
  }

  return (
    <BasicScreen
      {...stepsNavigationProps}
      flow={flow}
      title={title?.length ? title : requirement?.name}
      subtitle={requirement?.description}
      onSaveClick={onNextClick}
      onBackClick={onBackClick}
      saveLoading={loading}
      saveVisible={flow === 'onboarding'}
    >
      <SearchExistingCustomersProvider searchButtonVisible={false}>
        <BasePrincipalsScreen
          loading={loading}
          business={business}
          application={application}
          requirementProperties={requirement?.properties ?? []}
          createPerson={createPerson}
          updatePerson={updatePerson}
          createBusiness={createBusiness}
          updateBusiness={updateBusiness}
          updateApplication={updateApplication}
          inviteToApplication={inviteToApplication}
          onPrincipalRemoved={onPrincipalRemoved}
          partnerFeatureConfigurations={partner.data?.featureConfigurations ?? {}}
          minRequiredOwnershipPercentage={minRequiredOwnershipPercentage}
          disableEmailUpdate
        />
      </SearchExistingCustomersProvider>
    </BasicScreen>
  );
};
