import React, { useMemo } from 'react';
import { Chip } from '@mui/material';
import { amberPalette, colors, greenPalette, greyPalette, lightBluePalette, redPalette } from '@lama/design-system';
import type { ApplicationStatus } from '@lama/contracts';
import { useRecoilValue } from 'recoil';
import { partnerState } from '../../state/appState';

const chipStyles: Record<ApplicationStatus, { bgcolor: string; color: string; borderColor: string }> = {
  Cancelled: { bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[500] },
  ExpresslyWithdrawn: { bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[500] },
  WithdrawalOfApprovedApplication: { bgcolor: greyPalette[100], color: greyPalette[500], borderColor: greyPalette[500] },
  Rejected: { bgcolor: redPalette[50], color: redPalette[400], borderColor: redPalette[400] },
  Deleted: { bgcolor: redPalette[50], color: redPalette[400], borderColor: redPalette[400] },
  ApprovedNotAccepted: { bgcolor: redPalette[50], color: redPalette[400], borderColor: redPalette[400] },
  Draft: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  Incomplete: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  Disqualified: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  OnboardingCompleted: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  PendingApproval: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  PendingLost: { bgcolor: amberPalette[50], color: amberPalette[700], borderColor: amberPalette[700] },
  InReview: { bgcolor: lightBluePalette[50], color: lightBluePalette[700], borderColor: lightBluePalette[700] },
  Closing: { bgcolor: lightBluePalette[50], color: lightBluePalette[700], borderColor: lightBluePalette[700] },
  Closed: { bgcolor: greenPalette[50], color: colors.success.light, borderColor: colors.success.light },
};

const statusToChip = (
  status: ApplicationStatus,
  statusToDisplayNameMapping: Record<ApplicationStatus, string>,
): { label: string; style: {} } => ({
  label: statusToDisplayNameMapping[status],
  style: chipStyles[status],
});

export const ApplicationStatusChip = ({ status, size }: { status: ApplicationStatus; size?: 'medium' | 'small' }) => {
  const partner = useRecoilValue(partnerState);
  const { label, style } = useMemo(
    () => (partner ? statusToChip(status, partner.applicationStatusDisplayNameMapping) : { label: status, style: {} }),
    [partner, status],
  );
  return <Chip label={label} size={size} variant={'outlined'} sx={style} />;
};
