/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormikMoneyInputField } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { RequirementInput } from '../shared/RequirmentInput';
import { useGetRequiredFields } from '../../hooks/useGetRequiredFields';
import { BasicForm } from '../shared/BasicForm';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';

const validationSchema = yup.object({
  projectedRev1: yup.number(),
  projectedRev2: yup.number(),
});

export const ProjectedRevenueScreen: React.FC<ScreenProps> = ({ onNextClick, onBackClick, saveEnabled, ...stepsNavigationProps }) => {
  const { t } = useTranslation();
  const requiredFields = useGetRequiredFields();
  const { application } = useContext(ApplicationContext);
  const { mutate: updateBusiness } = useUpdateBusiness(application.id);
  const requirement = useGetCurrentRequirement();
  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement?.entityId)?.business,
    [application, requirement],
  );

  const initialValues = {
    projectedRev1: business?.projectedRevenue?.['2023']?.toString() ?? '',
    projectedRev2: business?.projectedRevenue?.['2024']?.toString() ?? '',
  };

  const onSubmit = useCallback(
    async (newValues: typeof initialValues) => {
      const { projectedRev1, projectedRev2 } = newValues;
      const projectedRevenuePayload = {
        '2023': projectedRev1 ? Number(projectedRev1) : null,
        '2024': projectedRev2 ? Number(projectedRev2) : null,
      };
      if (business) {
        updateBusiness({ businessId: business.id, updateBusinessPayload: { projectedRevenue: projectedRevenuePayload } });
      }
      onNextClick();
    },
    [business, onNextClick, updateBusiness],
  );

  return (
    <BasicForm initialValues={initialValues} validationSchema={validationSchema} submitCallback={onSubmit}>
      {({ handleSubmit, dirty, isValid, submitted }) => (
        <BasicScreen
          {...stepsNavigationProps}
          title={t('projectedRevenue.title')}
          subtitle={t('projectedRevenue.subtitle')}
          onSaveClick={handleSubmit}
          onBackClick={onBackClick}
          saveEnabled={!!saveEnabled || (dirty && isValid)}
        >
          <Flex flexDirection={'column'} gap={8}>
            <Flex flexDirection={'column'} gap={5}>
              <Text variant={'body1'} color={'disabled'}>
                {'2023'}
              </Text>
              <Flex flexDirection={'column'} gap={4}>
                <RequirementInput
                  name={'projectedRev1'}
                  label={t('projectedRevenue.label')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('projectedRevenue.2023')}
                  submitted={submitted}
                />
              </Flex>
            </Flex>
            <Flex flexDirection={'column'} gap={4}>
              <Text variant={'body1'} color={'disabled'}>
                {'2024'}
              </Text>
              <Flex flexDirection={'column'} gap={4}>
                <RequirementInput
                  name={'projectedRev2'}
                  label={t('projectedRevenue.label')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('projectedRevenue.2024')}
                  submitted={submitted}
                />
              </Flex>
            </Flex>
          </Flex>
        </BasicScreen>
      )}
    </BasicForm>
  );
};
