import React, { useCallback, useMemo } from 'react';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { ChevronRight, CheckCircleOutline } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useAsync, useAsyncFn } from 'react-use';
import { Flex, Spinner, Text } from '@lama/design-system';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { UploadIdCardIcon } from './UploadIdCardIcon';
import { UploadSelfieIcon } from './UploadSelfieIcon';

export const IdVerificationScreen: React.FC<ScreenProps> = (stepsNavigationProps) => {
  const [isIdCardUploaded, setIsIdCardUploaded] = React.useState(false);
  const [isSelfieUploaded, setIsSelfieUploaded] = React.useState(false);
  const [uploadsCompleted, setUploadsCompleted] = React.useState(false);

  const [{ loading: idCardUploadInProgress }, uploadIdCard] = useAsyncFn(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsIdCardUploaded(true);
  });

  const [{ loading: selfieUploadInProgress }, uploadSelfie] = useAsyncFn(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsSelfieUploaded(true);
  });

  const onVerifyWithMobileClick = useCallback(() => {}, []);

  const onUploadIdCardClick = useCallback(async () => {
    await uploadIdCard();
  }, [uploadIdCard]);

  const onUploadSelfieClick = useCallback(async () => {
    await uploadSelfie();
  }, [uploadSelfie]);

  useAsync(async () => {
    if (isIdCardUploaded && isSelfieUploaded) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setUploadsCompleted(true);
    }
  }, [isIdCardUploaded, isSelfieUploaded]);

  const steps: { buttonText: string; Icon: React.FC; onClick: () => void; isDone: boolean; isUploading: boolean }[] = useMemo(
    () => [
      {
        buttonText: 'Upload ID card',
        Icon: UploadIdCardIcon,
        onClick: onUploadIdCardClick,
        isDone: isIdCardUploaded,
        isUploading: idCardUploadInProgress,
      },
      {
        buttonText: 'Upload selfie',
        Icon: UploadSelfieIcon,
        onClick: onUploadSelfieClick,
        isDone: isSelfieUploaded,
        isUploading: selfieUploadInProgress,
      },
    ],
    [idCardUploadInProgress, isIdCardUploaded, isSelfieUploaded, onUploadIdCardClick, onUploadSelfieClick, selfieUploadInProgress],
  );

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={'ID Verification'}
      subtitle={'Complete this short process to authenticate yourself'}
      saveVisible={false}
    >
      <Flex flexDirection={'column'} gap={24} alignItems={'center'}>
        <Grid container spacing={7}>
          {steps.map(({ buttonText, Icon, onClick, isDone, isUploading }, index) => (
            <Grid item xs={12} md={6} key={buttonText} display={'flex'} justifyContent={'center'}>
              <Flex flexDirection={'column'} gap={2}>
                <Flex
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={4}
                  width={'260px'}
                  height={'260px'}
                  border={'1px solid rgba(0, 0, 0, 0.12)'}
                  paddingTop={6}
                  paddingBottom={6}
                >
                  <Icon />
                  <Flex gap={2} alignItems={'center'}>
                    {isDone ? <CheckCircleOutline color={'primary'} /> : null}
                    <Typography fontSize={'14px'} fontWeight={500} color={grey[400]}>{`Step ${index + 1}`}</Typography>
                  </Flex>
                </Flex>
                {isUploading ? (
                  <Box px={'10%'}>
                    <LinearProgress />
                  </Box>
                ) : (
                  <Button variant={'text'} color={'primary'} component={'label'} disabled={isDone}>
                    {buttonText}
                    <input type={'file'} hidden onChange={onClick} />
                  </Button>
                )}
              </Flex>
            </Grid>
          ))}
        </Grid>
        {uploadsCompleted ? (
          <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
            <Spinner size={'40px'} />
            <Typography fontSize={'15px'} fontWeight={600} color={'text.secondary'}>
              {'Processing your documents...'}
            </Typography>
          </Flex>
        ) : (
          <Flex flexDirection={'row'} gap={6} alignItems={'center'}>
            <Text variant={'body1'} color={'secondary'}>
              {'Or'}
            </Text>
            <Button
              variant={'contained'}
              color={'primary'}
              style={{ width: '230px' }}
              endIcon={<ChevronRight />}
              onClick={onVerifyWithMobileClick}
            >
              {'Verify with Mobile'}
            </Button>
          </Flex>
        )}
      </Flex>
    </BasicScreen>
  );
};
