import React, { useCallback, useContext, useState } from 'react';
import type { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentRequirement } from '../../../../hooks/useGetCurrentRequirement';
import type { DismissWithReasonDialogValues } from '../../DismissWithReasonDialog';
import { DismissWithReasonDialog } from '../../DismissWithReasonDialog';
import { useUpdateApplicationRequirementMutation } from '../../../../hooks/react-query/useUpdateApplicationRequirementMutation';
import { ApplicationContext } from '../../../../shared/contexts/ApplicationContext';

export const DismissRequirementButton: FC = () => {
  const { application } = useContext(ApplicationContext);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const currentRequirement = useGetCurrentRequirement();
  const { mutateAsync: updateRequirement, isPending: dismissingRequirement } = useUpdateApplicationRequirementMutation(
    application.id,
    currentRequirement?.id,
  );

  const handleClick = useCallback(async () => {
    setOpenDialog(true);
  }, []);

  const handleClose = useCallback(
    async (values: DismissWithReasonDialogValues | null) => {
      setOpenDialog(false);

      if (values === null || !currentRequirement) {
        return;
      }

      await updateRequirement({
        updateRequirementPayload: {
          dataByEntity: {
            [currentRequirement.entityId]: {
              statusOverride: 'Dismissed',
              dismiss: {
                ...values,
              },
            },
          },
        },
      });

      navigate('..');
    },
    [currentRequirement, updateRequirement, navigate],
  );

  return currentRequirement ? (
    <>
      <LoadingButton variant={'text'} color={'error'} onClick={handleClick} loading={dismissingRequirement}>
        {'Dismiss'}
      </LoadingButton>
      <DismissWithReasonDialog open={openDialog} handleClose={handleClose} />
    </>
  ) : null;
};
