import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikYesNoQuestion, FormikMoneyInputField } from '@lama/app-components';
import type { ScreenProps } from '../ScreenProps';
import { BasicForm } from '../shared/BasicForm';
import { BasicScreen } from '../shared/BasicScreen';
import { PropertyTypePicker } from '../shared/PropertyTypePicker';
import { FormikGooglePlacesAutocompleteInput } from '../shared/FormikGooglePlacesAutocompleteInput';

export const RealEstateDetailsScreen: FC<ScreenProps> = ({ onNextClick, onBackClick, saveEnabled, ...stepsNavigationProps }) => {
  const { t } = useTranslation();

  const initialValues = {
    propertyType: '',
    purchasePrice: '',
    currentLeasePrice: '',
    landOwnership: '',
    dealPartners: '',
    propertyAddress: '',
  };

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (newValues: typeof initialValues) => {
      // TODO: save values
      onNextClick();
    },
    [onNextClick],
  );

  return (
    <BasicForm initialValues={initialValues} submitCallback={onSubmit}>
      {({ handleSubmit, dirty, isValid }) => (
        <BasicScreen
          {...stepsNavigationProps}
          title={t('realEstateDetails.title')}
          subtitle={t('realEstateDetails.subtitle')}
          onSaveClick={handleSubmit}
          onBackClick={onBackClick}
          saveEnabled={!!saveEnabled || (dirty && isValid)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikGooglePlacesAutocompleteInput name={'propertyAddress'} placeholder={t('realEstateDetails.input')} />
            </Grid>
            <Grid item xs={12}>
              <PropertyTypePicker name={'propertyType'} label={'Property Type'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikMoneyInputField name={'purchasePrice'} label={'Purchase Price'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikMoneyInputField name={'currentLeasePrice'} label={'Current Lease Price'} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormikYesNoQuestion name={'landOwnership'} question={'Do you own the land?'} />
            </Grid>
            <Grid item xs={12}>
              <FormikYesNoQuestion name={'dealPartners'} question={'Will you have any partners for this deal?'} />
            </Grid>
          </Grid>
        </BasicScreen>
      )}
    </BasicForm>
  );
};
