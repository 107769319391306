/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useState, useContext, useMemo } from 'react';
import { Grid, ToggleButton } from '@mui/material';
import { xor } from 'lodash-es';
import { ConfirmLeave, useIsMobile } from '@lama/app-components';
import { applicationBorrowingBusinessSelector } from '@lama/data-formatters';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUpdateApplication } from '../../hooks/react-query/useUpdateApplication';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';

interface UseOfFundsScreenProps extends ScreenProps {
  maxSelection?: number;
  options?: string[];
}

export const UseOfFundsScreen: React.FC<UseOfFundsScreenProps> = ({
  onNextClick,
  onBackClick,
  maxSelection = 3,
  options: screenParamsUseOfFunds = [],
  flow,
  saveLoading,
  ...stepsNavigationProps
}) => {
  const isMobile = useIsMobile();
  const { application, product } = useContext(ApplicationContext);
  const business = useMemo(() => applicationBorrowingBusinessSelector(application), [application]);
  const requirement = useGetCurrentRequirement();

  const {
    mutateAsync: updateApplication,
    isPending: updatingApplication,
    isSuccess: applicationSuccessfullyUpdated,
  } = useUpdateApplication(application.id);
  const useOfFundsOptions = useMemo(
    () => product.useOfFundsOptions ?? screenParamsUseOfFunds,
    [product.useOfFundsOptions, screenParamsUseOfFunds],
  );

  const [useOfFunds, setUseOfFunds] = useState(application.useOfFunds ?? []);

  const onClick = useCallback(
    (_e: any, value: string) => {
      if (useOfFunds.includes(value)) {
        setUseOfFunds(useOfFunds.filter((v) => v !== value));
      } else if (useOfFunds.length < maxSelection) {
        setUseOfFunds([...useOfFunds, value]);
      }
    },
    [maxSelection, useOfFunds],
  );

  const onSaveClickHandler = useCallback(async () => {
    await updateApplication({ updateApplicationPayload: { useOfFunds } });
    onNextClick({ useOfFunds, businessUnformed: business?.unformed === null ? undefined : business?.unformed });
  }, [business?.unformed, onNextClick, updateApplication, useOfFunds]);

  const dirty = useMemo(() => !!xor(useOfFunds, application.useOfFunds).length, [useOfFunds, application.useOfFunds]);

  return (
    <ConfirmLeave shouldBlock={!!dirty && flow !== 'onboarding'}>
      <BasicScreen
        {...stepsNavigationProps}
        flow={flow}
        title={requirement?.name ?? 'How will you use the funds?'}
        subtitle={requirement?.description ?? `Choose up to ${maxSelection} options`}
        onSaveClick={onSaveClickHandler}
        saveEnabled={flow === 'onboarding' ? !!useOfFunds.length : dirty}
        onBackClick={onBackClick}
        saveLoading={updatingApplication || saveLoading}
        successSubmit={applicationSuccessfullyUpdated}
      >
        <Grid container spacing={isMobile ? 2 : 3}>
          {useOfFundsOptions.map((option) => (
            <Grid item xs={6} md={4} key={option}>
              <ToggleButton
                value={option}
                color={'primary'}
                onClick={onClick}
                selected={useOfFunds.includes(option)}
                disabled={!useOfFunds.includes(option) && useOfFunds.length === maxSelection}
                sx={{
                  '&.Mui-selected': {
                    borderColor: 'primary.main',
                  },
                  height: `${isMobile ? '80px' : 'auto'}`,
                }}
                fullWidth
              >
                {option}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </BasicScreen>
    </ConfirmLeave>
  );
};
